import React from 'react';

const Hero = ({ headline, imgSrc, imageAlt, mobileImage }) => {
	return (
		<section className="c-content-hero">
			{mobileImage && imgSrc && (
				<picture>
					<source media="(max-width: 599px)" srcSet={mobileImage} />
					<source media="(min-width: 600px)" srcSet={imgSrc} />
					<img className="o-fluid-img c-content-hero__img" srcSet={imgSrc} alt={imageAlt} />
				</picture>
			)}
			{!mobileImage && imgSrc && (
				<img className="o-fluid-img c-content-hero__img" src={imgSrc} alt={imageAlt} />
			)}
			<h1 className="c-content-hero__headline">{headline}</h1>
			<div className="c-content-hero__divider"></div>
		</section>
	);
};

export default Hero;
