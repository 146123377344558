const cleanCrumbLabels = (crumbs) => {
	crumbs.forEach((crumb) => {
		crumb.crumbLabel = crumb.crumbLabel.replace(/-/g, ' ');

		// Custom crumbLabels
		if (crumb.crumbLabel === 'resources') {
			crumb.crumbLabel = 'Resource Center'
		} else if (crumb.crumbLabel === 'faqs') {
			crumb.crumbLabel = 'Frequently Asked Questions'
		} else if (crumb.crumbLabel === 'family') {
			crumb.crumbLabel = 'Friends & Family'
		}
	});

	return crumbs;
};

export default cleanCrumbLabels;
